import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({component: Component,layout: Layout,isAuth,authUsername,profilePath, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            <Layout isAuth={isAuth} authUsername={authUsername} profilePath={profilePath}>
                <Component {...props} />
            </Layout>
        )} />
    );
};

export default PublicRoute;