import React, { Component } from 'react'; 
import { ProgressBar, Button } from 'react-bootstrap';
import { getEntries } from '../../utils/Helper';
import { UserContext } from '../../utils/UserContext';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopupDialog from '../dialog/PopupDialog';
import AppreciatonForm from '../form/AppreciationForm';

export default class StatsPanel extends Component {
        static contextType = UserContext;
        _isMounted = false;

        constructor(props) {
            super(props);
            this.state = {
                dailyentries: 0,
                appreciationDone: null,
                dailyconclusiondone: null,
                showDialog: false,
            }

            this.getStats = this.getStats.bind(this);
        }

        componentDidMount() {
            this._isMounted = true;
            this.getStats()
        }

        getStats = () => {
            const that = this;
            const user = this.context;

            getEntries(user.userId,function(data){
                if(data !== null) {
                    var dailyentries = [];
                    var done = false;
                    var dailyconclusiondone = false;
                    data.forEach(element => {
                        if(new Date(element.timestamp).toLocaleDateString() === new Date().toLocaleDateString() && element.type === 'entry') {
                            dailyentries.push(element);
                        }else if(new Date(element.timestamp).toLocaleDateString() === new Date().toLocaleDateString() && element.type === 'appreciation') {
                            done = true;
                        }else if(new Date(element.timestamp).toLocaleDateString() === new Date().toLocaleDateString() && element.type === 'dailyconclusion') {
                            dailyconclusiondone = true;
                        }
                            //console.log(new Date(element.timestamp).toLocaleDateString());
                    });
    
                    if(that._isMounted) {
                        that.setState({
                            dailyentries: dailyentries,
                            dailyentriesnumber: dailyentries.length,
                            progressvalue: 100/6*dailyentries.length,
                            appreciationDone: done,
                            dailyconclusiondone: dailyconclusiondone
                        });    
                    }    
                }
            });
        } 

        componentWillUnmount() {
            this._isMounted = false;
        }

        hide = () => {
            this.setState({showDialog: false});
        }

        show = () => {
            console.log("show");
            this.setState({showDialog: true});
        }

        render() {
            var type = "entrycreateform";
            var btn = <Button className="btn w-100 p-1 mt-4" onClick={this.show}><FontAwesomeIcon className="mr-2" icon="feather"/>Add diary entry</Button>;
            if(this.state.dailyentriesnumber === 6) {
                type = "dailyconclusion";
                btn = <Button className="btn create-btn w-100 p-1 mt-1" onClick={this.show}><FontAwesomeIcon className="mr-2" icon="feather"/>Write your daily conclusion</Button>;
            }

            if(this.state.dailyconclusiondone === true) {
                btn = null;
            } 

            return(
                <>
                <div className="statsPanel">
                    <h5 className="mb-2">{ this.state.dailyentriesnumber }/6 Daily entries</h5>
                    <ProgressBar striped variant="primary" now={this.state.progressvalue} key={1} />
                    { this.state.dailyconclusiondone &&
                    <>
                    <h5 className="mt-3">Daily conclusion</h5>
                    <p className="text-primary italic"><FontAwesomeIcon icon="check-circle"/> Done!</p>
                    </>
                    }

                    { this.state.dailyentriesnumber === 6 && this.state.dailyconclusiondone !== true &&
                    <p className="mt-3">Congratulations! You already wrote your 6 entries today. Finish off by writing your daily conclusion</p>
                    }
                    { this.state.dailyentriesnumber === 6 && this.state.dailyconclusiondone === true &&

                    <p className="mt-3">Congratulations! You are done with your six times book for today.</p>
                    }
                    {btn}
                    <PopupDialog type={type} stats={this.state} showDialog={this.state.showDialog} hide={this.hide} />
                </div>
                <div className="statsPanel">
                    <h5 className="mb-3">Daily appreciation</h5>
                    { this.state.appreciationDone !== null &&
                        <>
                        { this.state.appreciationDone === false &&
                            <>
                            <AppreciatonForm></AppreciatonForm>
                            </>
                        }
                        { this.state.appreciationDone &&
                            <div className="buttonWrap">
                            <p className="text-primary"><FontAwesomeIcon icon="check-circle"/> Done!</p>
                            <Link className="btn btn-link showDiary-btn" to="/diary#dailyappreciation"><FontAwesomeIcon icon="book"/> Show in diary</Link>
                            </div>
                        }
                        </>
                    }
                </div>
                </>
            );    
        }
}