import React, { useState,useEffect }  from 'react';
import UnfilteredPagesEditor from "../form/UnfilteredPagesEditor";
import UnfilteredPagesViewer from "../layout/UnfilteredPagesViewer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter } from 'react-router-dom';
import PrivateRoute from '../../utils/PrivateRoute';
import { useRouteMatch, Switch, Link } from 'react-router-dom';
import { UserContext } from '../../utils/UserContext';
import { getUnfilteredPages } from '../../utils/Helper';
import UFPageItem from '../layout/UFPageItem';
import Masonry from 'react-masonry-css'
const breakpointColumnsObj = {
    default: 2,
    768: 1,
};
  
function UnfilteredPages() {
    let { path, url } = useRouteMatch();
    const user = React.useContext(UserContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        getUnfilteredPages(user.userId,(data) => {
            setData(data);
        });
    }, [user.userId]);
    
    const updateData = () => {
        getUnfilteredPages(user.userId,(data) => {
            setData(data);
        });
    }

    return (
        <>
        { data &&
            <div className="unfilteredPages p-3 py-4" id="unfilteredPages">
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path={path}>
                        <div className="bg-primary py-2 d-flex profileHeader">
                            <p className="m-0">Unfiltered Pages</p>
                            <Link className="ml-auto nostyle" variant="link" to={`${url}/add`}><FontAwesomeIcon icon="plus-circle" /> <small>Create page</small></Link>
                        </div>      
                        <div className="px-4 py-2">
                            <small className="font-italic py-3">This space can be used to write down what's on your mind, to set yourself goals, to write poetry, to create a collection of your favorite youtube meditations and pretty much anything you would like to have in your diary. There are really no creative boundaries here...</small>
                            <div className="pages mt-3 py-2">
                                <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column">
                                { data.map((page,i) =><UFPageItem key={i} page={page}/>)}
                                </Masonry>

                            </div>
                        </div>
                        </PrivateRoute>
                        <PrivateRoute path={`${path}/add`}>
                            <UnfilteredPagesEditor user={user}/>
                        </PrivateRoute>
                        <PrivateRoute path={`${path}/edit/:postid`}>
                            <UnfilteredPagesEditor user={user} data={data}/>
                        </PrivateRoute>
                        <PrivateRoute path={`${path}/:postid`}>
                            <UnfilteredPagesViewer user={user} data={data}/>
                        </PrivateRoute>
                    </Switch>    
                </BrowserRouter>
            </div>
        }
        </>
    );
}

export default UnfilteredPages;