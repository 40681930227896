import React from 'react';
import VibeReply from './VibeReply';
import fire from '../../config/Fire';
import {Button} from 'react-bootstrap';

class ReplyFeed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            commentNumber: null,
            shownComments: 2,
            data: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;
        //console.log(this.props.postid);

        this.getRelatedReplies(that.props.postid,function(data) {
            if(that._isMounted && data != null) {
                var arr = [];
                for (var key of Object.keys(data)) {
                    var line=data[key];
                    line.replyid= key;
                    arr.push(line);
                }

                that.props.setCommentNumber(arr.length);
                that.setState({commentNumber: arr.length});
                that.setState({data: arr});

            }else if(data == null) {
                that.props.setCommentNumber(0);
                that.setState({commentNumber: 0});
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getRelatedReplies(postid,callback) {
        fire.database().ref('/replies').orderByChild('replyToPost').equalTo(postid).on('value', function(snapshot) {
            callback(snapshot.val());
        });
    }

    showMore = () => {
        const that = this;
        if(that.state.shownComments >= that.state.commentNumber) {
            //hide comments
            that.setState({shownComments: 0});
            window.location.href="home#"+that.props.postid;
        } else {
            that.setState({shownComments: that.state.shownComments + 6});
        }
    }

    render(){
        var text = "";
        if(this.state.shownComments >= this.state.commentNumber) {
            text = "Hide comments";

        } else {
            text = "Display all " + this.state.commentNumber + " comments";
        }

        return (
            <>
            { this.state.commentNumber !== 0 &&
                <>
                <div className="replyFeed w-100">{this.state.data.slice(0,this.state.shownComments).map(item => <VibeReply key={item.replyid} replyid={item.replyid} userid={item.userid} timestamp={item.timestamp} replyToPost={item.replyToPost} authUsername={this.props.authUsername} replyContent={item.replyContent} />)}</div>
                <div className="w-100 row buttonWrap">
                    <Button variant="link" className="mx-auto" onClick={this.showMore}>
                    {text}
                    </Button>
                </div>
                </>
            }
            </>
        );
    }
}

export default ReplyFeed;