import * as firebase from 'firebase';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAIyEwIlB-xizejGKF1lQQ3pwE2GMplGq8",
  authDomain: "vibeshare-99e2f.firebaseapp.com",
  databaseURL: "https://vibeshare-99e2f.firebaseio.com",
  projectId: "vibeshare-99e2f",
  storageBucket: "vibeshare-99e2f.appspot.com",
  messagingSenderId: "765951721966",
  appId: "1:765951721966:web:a38264f49512025f3f0eff",
  measurementId: "G-EYDQEE8LRE"
};


// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
export default fire;
