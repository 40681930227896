import React from 'react';
import SideNavigation from './SideNavigation';
import { Button,Nav,Navbar } from 'react-bootstrap';
import fire from 'firebase';
import logo512 from '../../assets/logo_light.png';
import { Link, withRouter, Switch } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AdSpace from './AdSpace';
import StatsPanel from './StatsPanel';
import { Helmet } from 'react-helmet';
import { getCookie } from '../../utils/Helper';
import PopupDialog from '../dialog/PopupDialog';
import VibePost from '../reply/VibeReply';
import PrivateRoute from "../../utils/PrivateRoute";
import { UserContext } from "../../utils/UserContext"; 

class VibeShare extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showMotd: false,
    };
  }

  logout() {
    fire.auth().signOut();
  }

  componentDidMount() {
    const user = this.context;
    this.setState({userid: user.userId});
    document.body.classList.add('vibeshare');
    this.showMotd();
  }

  componentWillUnmount() {
    document.body.classList.remove('vibeshare');
  }

  visited = () => {
    var midnight = new Date();
    midnight.setHours(23,59,59,0);

    document.cookie = `visited=1;expires=${midnight.toGMTString()};`
  }

  showMotd = () => {
    var visited = getCookie('visited');
    if(visited != 1 ) {
      //if not visited show motd and setcookie
      this.setState({
        showMotd: true,
      });
    }
  }

  showMotdDirectly = () => {
    this.setState({showMotd: true })
  }

  hide = () => {
    this.setState({
      showMotd: false,
    });
    this.visited();
  }

  render() {   
    window.scrollTo(0,0);
    console.log(this.props);
    var buttonLeft, buttonRight;

      if(this.props.isAuth === false) {
          buttonLeft = <Button variant="link"><Link to="/login">Log in</Link></Button>;
          buttonRight = <Button variant="outline-primary"><Link to="/signup">Sign up</Link></Button>;
      }else if(this.props.isAuth === true) {
          buttonLeft = <Button variant="link" onClick={this.logout}><FontAwesomeIcon icon="sign-out-alt" /> Log out</Button>;
          buttonRight = <Button variant="link" className="d-flex justify-content-start align-items-center flex-row"><Link className="btn-link" to={this.props.profilePath}><FontAwesomeIcon icon="user" />  Account</Link></Button>;
      }

      var path = this.props.location.pathname;

      var pagetitle = (path.indexOf("/") === 0)? path.substring(1,path.length):path;
      pagetitle = pagetitle.charAt(0).toUpperCase() + pagetitle.substring(1,pagetitle.length);
      if(path === this.props.profilePath) {
        pagetitle = 'My account';
      }
      
      return (
        <>
        <Helmet>
            <title>SixTimes - {pagetitle}</title>
        </Helmet>
        <div>
          <PopupDialog showDialog={this.state.showMotd} hide={this.hide} type="motd"/>
          <Navbar bg="light" expand="md" className="w-100 app-navbar">
          <Navbar.Brand><Link to="/"><img height="40px" className="brandImg" src={logo512} alt="VibeShare logo"/></Link><span className="badge badge-light ml-3"><small>Beta</small></span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
              </Nav>
              {buttonLeft}
              {buttonRight}
          </Navbar.Collapse>
          </Navbar>

          <div className="vibeShareWrapper padSect">
            <div className="row mx-auto">
                <aside className="col-md-3 left">
                  <SideNavigation profilePath={this.props.profilePath}></SideNavigation>
                  <StatsPanel></StatsPanel>
                  <Button variant="link" className="w-100 mt-2" onClick={this.showMotdDirectly}><small>Need some extra Motivation? :)</small></Button>
                </aside>
                <main className="col-md-6">
                    {this.props.children}
                </main>
                <aside className="col-md-3 ad">
                  <AdSpace></AdSpace>
                </aside>
            </div>
          </div>
        </div>
        </>
      );
  }
}

export default withRouter(VibeShare);