import React from 'react';
import { Button } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EntryCreateForm from '../form/EntryCreateForm';
import AppreciatonForm from '../form/AppreciationForm';
import DailyConclusionForm from '../form/DailyConclusionForm';

export default class ConfirmationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            affirmation: '',
        }

        this.hide = this.hide.bind(this);
    }

    hide = (param) => {
        this.props.hide();
        document.body.classList.remove('noscroll');
    }

    hideSave = () => {
        this.props.hide(true);
    }

    hideDontSave = () => {
        this.props.hide(false);
    }

    componentDidMount() {
        this.getAffirmation();
        document.addEventListener("keydown", event => {
            if (event.isComposing || event.keyCode === 27) {
                this.hide();
            }
        });
    }

    componentDidUpdate(nextProps) {
        if(this.props.showDialog !== nextProps.showDialog && nextProps.showDialog === true && nextProps.type === 'affirmation') {
            this.getAffirmation();
        }
    }

    getAffirmation = (e) => {
        const that = this;
        var req = new XMLHttpRequest();
        req.open("GET","/affirmations",true);

        req.onload = function () {
            var data = JSON.parse(this.response);
            that.setState({
                affirmation: data.affirmation
            });
        }

        req.send({ 'request': "authentication token" });
        
        if(e) {
            e.preventDefault();
            e.target.parentElement.blur();
        }
    }

    render() {
        if(this.props.showDialog === true) {
            document.body.classList.add('noscroll');
        }else {
            document.body.classList.remove('noscroll');
        }

        var closebtn = '';
        if(this.props.type === 'motd') {
            closebtn = 'Start your daily journal';
        }else if(this.props.type === 'editorgoback') {
            closebtn = 'Cancel';
        }else {
            closebtn = 'Close'; 
        }

        return(
            <TransitionGroup component={null}>
            {this.props.showDialog &&
            <CSSTransition classNames="fade" timeout={300}>
            <div id="popupDialog" className="modal popupDialog" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    { this.props.type === 'entrycreateform' &&
                        <EntryCreateForm stats={this.props.stats} hideCallback={this.hide} />
                    }
                    { this.props.type === 'dailyconclusion' &&
                        <DailyConclusionForm stats={this.props.stats} hideCallback={this.hide} />
                    }
                    { this.props.type === 'dailyappreciation' &&
                        <AppreciatonForm />
                    }
                    { this.props.type === 'motd' &&
                        <>
                        <blockquote className="text-center">{this.state.affirmation}</blockquote>
                        <Button variant="link" id="reload" onClick={this.getAffirmation}><small><FontAwesomeIcon icon="redo"/> Load new affirmation</small></Button>
                        </>
                    }
                    { this.props.type === 'editorgoback' &&
                        <>
                        <h3>Save your changes for this page?</h3>
                        <Button variant="primary" className="mt-3 mb-2" onClick={this.hideSave}>Save</Button>
                        <Button variant="primary" onClick={this.hideDontSave}>Don't save</Button>
                        </>
                    }
                    <div className="w-100 d-flex justify-content-center align-items-center">
                        <Button variant="outline" onClick={this.hide} className="mt-2"><FontAwesomeIcon icon="window-close"/> {closebtn}</Button>
                    </div>
                    </div>
                </div>
            </div>
            </CSSTransition>}
            </TransitionGroup>
        );
    }
}