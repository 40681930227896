import React from 'react';
import VibePost from './VibePost';

class VibeFeed extends React.Component {
    render(){
        var rawdata;
        if(this.props.data != null) {
            rawdata = this.props.data;
        }
  
        var arr = [];
        for (var key of Object.keys(rawdata)) {
            var line=rawdata[key];
            arr.push(line);
        }

        console.log(arr);

        return (
            <div className="vibeFeed row mx-auto w-100">{arr.map(item => <VibePost  key={item.postid} postid={item.postid} userid={item.userid} authid={this.props.authid} mood={item.mood} action={item.action} type={item.type} authUsername={this.props.authUsername} timestamp={item.timestamp} content={item.content} public={item.public}/>)}</div>
        );
    }
}

export default VibeFeed;