import React from 'react';

export default class VibeFooter extends React.Component {
    render() {
        return(
        <footer className="container-fluid">
            <div className="row widgets pt-4 pb-4">
                <div className="col-xs-12 col-sm-12 col-md-4">
                    <ul className="list-unstyled">
                        <li><a href="./"><i className="fa fa-angle-double-right"></i>Home</a></li>
                        <li><a href="./home#angebot"><i className="fa fa-angle-double-right"></i>Angebot</a></li>
                        <li><a href="./ueber-uns"><i className="fa fa-angle-double-right"></i>Über uns</a></li>
                        <li><a href="./kontakt"><i className="fa fa-angle-double-right"></i>Kontakt</a></li>
                    </ul>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4">
                    <ul className="list-unstyled">
                        <li><a href="mailto:office@websty.com"><i className="fa fa-angle-double-right"></i>office@vibeshare.com</a></li>
                        <li><a href="/imprint"><i className="fa fa-angle-double-right"></i>Imprint</a></li>
                    </ul>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4">
                    <ul className="list-unstyled">
                        <li>
                            <p>
                                SixTimes 
                                <br />
                                based in
                                <br />
                                Vienna, Austria
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row d-flex justify-content-center copyright py-3">
                2020 @ SixTimes &copy; All rights Reserved | <a href="/imprint" style={{color:'#fff'}}> &nbsp;Imprint</a>
            </div>
        </footer>
        );
    }
}