import React from 'react';
import SignupForm from '../form/SignupForm';

class VibeSignup extends React.Component {
    componentDidMount() {
        document.body.classList.add('signup');
    }

    render() {
        return (
          <div className="container-fluid padSect signupPage">
            <div className="col-md-6 mx-auto signupSection">
            <h1>Sign up for SixTimes</h1>
            <SignupForm></SignupForm>
            </div>
            </div>
        );
    }
}

export default VibeSignup;