import React, { Component } from 'react';
import { EntryContext } from '../../../utils/EntryContext';
import { Timeline, Event } from "react-timeline-scribble";
import { formatDate, entryTitle, getCurrentTimestamp } from '../../../utils/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ProgressBar } from 'react-bootstrap';

class DailyView extends Component {
    static contextType = EntryContext;
    

    constructor(props) {
        super(props);
        this.state = {
            dayToShow: this.props.dayToShow || getCurrentTimestamp(),
            diaryentries: [],
            dailyappreciation: null,
            dailyconclusion: null,
            data: null,
            activeDates: [],
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.setState({dayToShow: e.target.value},() => {
            this.setDailyView();
        });
    }
    
    componentDidMount() {
        const data = this.context;
        this.setState({
            data: data
        },() => {
            this.setDailyView();
            console.log(this.state.data);
        });
        console.log(this.state.dayToShow);
    }

    setDailyView(){
        var data = this.state.data;
        var dayToShow = this.state.dayToShow;
        var diaryentries = [];
        var dailyappreciation = null;
        var dailyconclusion = null;
        var activeDates = [];

        if(data !== null) {
            data.forEach((element,index,arr) => {
                if(new Date(element.timestamp).toLocaleDateString() === new Date(dayToShow).toLocaleDateString() && element.type === 'entry') {
                    let num = diaryentries.push(element);
                    num -= 1;
                    diaryentries[num]['key'] = num+''; 
                }else if(new Date(element.timestamp).toLocaleDateString() === new Date(dayToShow).toLocaleDateString() && element.type === 'appreciation') {
                    dailyappreciation = element;
                }else if(new Date(element.timestamp).toLocaleDateString() === new Date(dayToShow).toLocaleDateString() && element.type === 'dailyconclusion') {
                    dailyconclusion = element;
                }
                
                let prevElement = arr[index-1];
                if(prevElement != null && new Date(element.timestamp).toLocaleDateString() !== new Date(prevElement.timestamp).toLocaleDateString()) {
                    activeDates.push(element.timestamp);
                }
                if(prevElement == null) {
                    activeDates.push(element.timestamp);
                }
                //console.log(new Date(element.timestamp).toLocaleDateString());
            });
        }

        this.setState({
            activeDates: activeDates,
            diaryentries: diaryentries,
            dailyappreciation: dailyappreciation,
            dailyconclusion: dailyconclusion,
        });
    }
 
    nextDay = () => {
        var activeDates = this.state.activeDates;
        var testDate = this.state.dayToShow;
        var bestNextDate = activeDates.length;
        let rmI;
        activeDates.find((el,i) => {
            if(new Date(el).toLocaleDateString() == new Date(testDate).toLocaleDateString()) {
                activeDates.splice(rmI,1)
            }
        });

        var max_date_value = Math.abs((new Date(0,0,0)).valueOf());
        var bestNextDiff = -max_date_value;

        var currDiff = 0;

        for(let i = 0; i < activeDates.length; i++){
            currDiff = new Date(testDate) - new Date(activeDates[i]);
            if(currDiff < 0 && currDiff > bestNextDiff){
                bestNextDate = i;
                bestNextDiff = currDiff;
            }
        }

        let dayToSet = activeDates[bestNextDate];
        console.log(dayToSet)
        if(dayToSet) {
            this.setState({dayToShow: dayToSet},() => {
                this.setDailyView(); 
            });   
        }    
    }

    prevDay = () => {
        var activeDates = this.state.activeDates;
        var testDate = this.state.dayToShow;
        let rmI;
        activeDates.find((el,i) => {
            if(new Date(el).toLocaleDateString() == new Date(testDate).toLocaleDateString()) {
                rmI = i;
            }
        });
        rmI && activeDates.splice(rmI,1);
        
        var bestPrevDate = activeDates.length;

        var max_date_value = Math.abs((new Date(0,0,0)).valueOf());
        var bestPrevDiff = max_date_value;

        var currDiff = 0;

        for(let i = 0; i < activeDates.length; i++){
            currDiff = new Date(testDate) - new Date(activeDates[i]);
            if(currDiff > 0 && currDiff < bestPrevDiff){
                bestPrevDate = i;
                bestPrevDiff = currDiff;
            }   
        }

        console.log(activeDates)
        console.log(testDate)

        let dayToSet = activeDates[bestPrevDate];

        if(dayToSet) {
            console.log(dayToSet)
            this.setState({dayToShow: dayToSet},() => {
                this.setDailyView(); 
            });   
        }    
    }

    editVibe = (postid) => {
        //mabye implemented in future
    }

    render() {
        var dayToShow = this.state.dayToShow;
        var diaryentries = this.state.diaryentries;
        var dailyappreciation = this.state.dailyappreciation;
        var dailyconclusion = this.state.dailyconclusion;
        var activeDates = this.state.activeDates;
        let pageNum = '--';
        activeDates.forEach((timestamp,index) => {
            if(new Date(dayToShow).toLocaleDateString() === new Date(timestamp).toLocaleDateString()) {
                pageNum = index+1;
            }
        });

        return(
            <div id="dailyview">
            <div className="datepicker py-3 d-flex justify-content-center flex-row">
                <input type="date" id="daytoshow" name="dayToShow" onChange={this.handleChange} value={formatDate(this.state.dayToShow)} />
            </div>
            <div className="datepicker py-3 d-flex justify-content-center align-items-center flex-row">
                <Button variant="link" onClick={this.prevDay}><FontAwesomeIcon icon="arrow-left" /></Button>
                <h3 className="mb-0">Page {pageNum}/{activeDates.length}</h3>
                <Button variant="link" onClick={this.nextDay}><FontAwesomeIcon icon="arrow-right"  /></Button>
            </div>
            <div className="stats px-4 pt-1 pb-3">
                <p className="mb-2">{ diaryentries.length }/6 Daily entries</p>
                <ProgressBar striped variant="primary" now={diaryentries.length*100/6} key={1} />
                { dailyconclusion !== null &&
                <p className="mt-3">Daily conclusion <FontAwesomeIcon className="text-primary" icon="check-circle"/></p>
                }
                { dailyappreciation !== null &&
                <p className="mt-3">Daily appreciation <FontAwesomeIcon className="text-primary" icon="check-circle"/></p>
                }      
                { diaryentries.length === 0 &&
                <p className="pt-3">Unfortunately no entries were made on this day :(</p>
                }           
            </div>
            { diaryentries.length !== 0 &&
            <hr/>
            }
            <div id="dailyentriesWrapper">
            <Timeline>
            { dailyconclusion !== null  &&
            <div className="dailyconclusion">
            <Event interval={new Date(dailyconclusion.timestamp).toLocaleTimeString()} title={<h3 className="d-flex align-items-center">Daily conclusion <FontAwesomeIcon className="text-primary ml-1" icon="check-circle"/></h3>} key={dailyconclusion.postid}>
            <p className="vibeText positiveaction d-flex flex-column align-items-start">
            { dailyconclusion.content[0].map(subitem =>
                <span className="d-flex flex-row align-items-center"><FontAwesomeIcon title="positive action" icon="plus-circle"/>{subitem}</span>
            ) 
            }
            </p>
            <p className="vibeText negativeaction d-flex flex-column align-items-start">
            { dailyconclusion.content[1].map(subitem =>
                <span className="d-flex flex-row align-items-center"><FontAwesomeIcon title="negative action" icon="minus-circle"/>{subitem}<br/></span>
            )
            }
            </p>
            <p className="vibeText todo">
                <FontAwesomeIcon title="ToDo" icon="list-ul"/>{dailyconclusion.content[2]}
            </p>
            <Button variant="link" className="edit-btn" onClick={this.editVibe(dailyconclusion.postid)}>Edit</Button>
            </Event>
            </div>
            }
            { diaryentries.map(item => 
                <Event interval={new Date(item.timestamp).toLocaleTimeString()} title={entryTitle(item.key)} key={item.key}>
                    <div className="vibePostInner" id={item.postid}>  
                        <div className="vibeEntry">
                        <p className="vibeText positiveaction">
                            <FontAwesomeIcon title="positive action" icon="plus-circle"/> {item.content[0]}
                        </p>
                        <p className="vibeText negativeaction">
                            <FontAwesomeIcon title="negative action" icon="minus-circle"/> {item.content[1]}
                        </p>
                        <p className="vibeText todo">
                            <FontAwesomeIcon title="ToDo" icon="list-ul"/> {item.content[2]}
                        </p>
                        </div>
                        <Button variant="link" className="edit-btn" onClick={this.editVibe(item.postid)}>Edit</Button>
                    </div>
                </Event>
            )}
            { dailyappreciation !== null &&
            <Event interval={new Date(dailyappreciation.timestamp).toLocaleTimeString()} title={""} key={dailyappreciation.postid}>
            <hr/>
            <h3 id="dailyappreciation" className="d-flex align-items-center">Daily appreciation <FontAwesomeIcon className="text-primary ml-1" icon="check-circle"/></h3>
            <p className="vibeText">{dailyappreciation.content}</p>
            <Button variant="link" className="edit-btn" onClick={this.editVibe(dailyappreciation.postid)}>Edit</Button>
            </Event>
            }        
        </Timeline>   
       
        </div>
        </div>
        );
    }
}

export default DailyView;