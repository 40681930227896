import React from 'react';
import fire from '../../config/Fire';
import { Button } from 'react-bootstrap';
import { getCurrentTimestamp } from '../../utils/Helper';
import { PurpleSwitch } from '../form/PurpleSwitch';
import Creatable from 'react-select/creatable';
import { UserContext } from '../../utils/UserContext';

class DailyConclusionForm extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            public: false,
            userid: null,
            threenegativeactions: [],
            threepositiveactions: [],
            appreciationDone: null,
            dailyentries: null,
            posoptions:  [],
            negoptions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChangePos = this.handleSelectChangePos.bind(this);
        this.handleSelectChangeNeg = this.handleSelectChangeNeg.bind(this);
        this.toggleChecked = this.toggleChecked.bind(this);
        this.isValidNewOption = this.isValidNewOption.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        console.log(this.state);
    }

    handleSelectChangePos(e) {
        this.setState({
            threepositiveactions: e
        },console.log(this.state)
        );
    }

    handleSelectChangeNeg(e) {
        this.setState({
            threenegativeactions: e
        },console.log(this.state)
        );
    }

    componentDidMount() {
        const user = this.context;
        const stats = this.props.stats;
        console.log(stats);
        const that = this;
        this.setState({
            appreciationDone: stats.appreciationDone,
            dailyentriesnumber: stats.dailyentriesnumber,
            dailyentries: stats.dailyentries
        },() => {
            var pos = [];
            var neg = [];
            that.state.dailyentries.forEach((value,key) =>{
                pos.push({value: key+value.content.positiveaction, label: value.content.positiveaction});
                neg.push({value: key+value.content.negativeaction, label: value.content.negativeaction});
            });    

            that.setState({
                posoptions: pos,
                negoptions: neg,
                userid: user.userId,
            });

        });

    }

    isValidNewOption = (inputValue, selectValue) => 
        inputValue.length > 0 && selectValue.length < 3;
    

    postEntry = (e) => {
        e.preventDefault();

        var entryData = {
            userid: this.state.userid,
            timestamp: getCurrentTimestamp(),
            content: {
                positiveactions: this.state.threepositiveactions,
                negativeactions: this.state.threenegativeactions,
                todo: this.state.todo
            },
            public: this.state.public,
            type: 'dailyconclusion'
        };

        console.log(entryData);

        var privateorpublic = '';
        if(entryData.public === false) {
            privateorpublic = 'private';
        }

        fire.database().ref(privateorpublic+'vibes/').push(entryData)
        .then(function() {
          console.log("successfully wrote entry data to database");
        }).catch(function(error) {
          console.log(error.message);
        });

        this.reinit();
        this.props.hideCallback();
    }

    reinit() {
        this.setState({
            public: true,
            userid: null,
            threenegativeactions: [],
            threepositiveactions: [],
            appreciationDone: null,
            dailyentries: null,
            posoptions:  [],
            negoptions: []
        });
    }

    toggleChecked = () => {
        const that = this;
        this.setState({ public: !that.state.public });
    }

    render() {
        return(
            <div className="entryFormWrapper">
                { this.state.dailyentries !== null &&
                <form className="entryForm" onSubmit={this.postEntry}>
                    <div className="w-100 row buttonWrap d-flex align-items-start justify-content-end flex-column">
                    <p>Select your best three and your worst three actions of the day</p>
                    <h3>Top 3 positive actions{this.state.appreciationDone}</h3>
                    <Creatable required onChange={this.handleSelectChangePos} isValidNewOption={this.isValidNewOption} classNamePrefix="select" hideSelectedOptions={true} className="select" name="posselect" isMulti options={this.state.threepositiveactions.length < 3?this.state.posoptions:null}/><br/>
                    <h3>Top 3 negative actions</h3>
                    <Creatable required onChange={this.handleSelectChangeNeg} isValidNewOption={this.isValidNewOption} classNamePrefix="select"  hideSelectedOptions={true} className="select" name="negselect" isMulti options={this.state.threenegativeactions.length < 3?this.state.negoptions:null}/><br/>
                    <h3>ToDo/Conclusion</h3>
                    <textarea onChange={this.handleChange} rows="2" required value={this.state.todo} name="todo" id="todo" placeholder="How can you improve in the future, regarding your recent actions?"></textarea><br/>
                    <small className="font-italic mt-2">Try to always keep it short and simple, for this journal to be most effective</small>
                    <label className="mt-2">
                        Public
                        <PurpleSwitch size="small" checked={this.state.public} onChange={this.toggleChecked} />
                    </label>
                    <Button type="submit" className="mt-1 w-100" variant="primary">Add entry</Button>
                    </div>
                </form>                      
                }
            </div>
        );
    }
}

export default DailyConclusionForm;