import React, { Component } from 'react';
import { EntryContext } from '../../../utils/EntryContext';
import { Timeline, Event } from "react-timeline-scribble";
import { getWeek, entryTitle, getCurrentTimestamp, getDateOfISOWeek } from '../../../utils/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ProgressBar } from 'react-bootstrap';

class WeeklyView extends Component {
    static contextType = EntryContext;

    constructor(props) {
        super(props);
        this.state = {
            weekToShow: null,
            diaryentries: [],
            dailyappreciation: null,
            dailyconclusion: null,
            data: null,
            fakerleft: null,
        }
        this.input = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.setState({weekToShow: e.target.value});
    }
    
    componentDidMount() {
        console.log(getWeek(new Date("2020-07-31T19:05:36.000Z")));
        console.log(getCurrentTimestamp());
        if(this.props.weekToShow == null) {
            this.setState({
                weekToShow: getWeek(getCurrentTimestamp())
            });
        }

        this.setState({
            data: this.context,
        });

        this.resize();
    }

    resize = () => {
        this.setState({            
            fakerleft: this.input.current.offsetLeft,
        });
    }


 
    nextWeek = () => {
        var firstDay = getDateOfISOWeek(this.state.weekToShow);
        var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
        this.setState({
            weekToShow: getWeek(nextWeek)
        });
    }

    prevWeek = () => {
        var firstDay = getDateOfISOWeek(this.state.weekToShow);
        var prevWeek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.setState({
            weekToShow: getWeek(prevWeek)
        });
    }

    editVibe = () => {

    }

    render() {
        console.log(this.state.weekToShow)
        var weekToShow = this.state.weekToShow;
        var data = this.state.data;
        var days = [];
        var monday, sunday;

        if(data !== null && weekToShow !== null) {
            monday = new Date(getDateOfISOWeek(this.state.weekToShow)).toLocaleDateString();
            sunday = new Date(getDateOfISOWeek(this.state.weekToShow));
            var currentDate = sunday.getDate()+6;
            currentDate = sunday.setDate(currentDate);
            sunday = sunday.toLocaleDateString();
            for(let i = 0; i < 7; i++) {
                var mon = new Date(getDateOfISOWeek(this.state.weekToShow));
                var currentDate = mon.getDate()+i;
                currentDate = mon.setDate(currentDate);
                console.log(currentDate);
                var diaryentries = [];
                var dailyappreciation = null;
                var dailyconclusion = null;
                data.forEach(element => {
                    if(new Date(element.timestamp).toLocaleDateString() === new Date(currentDate).toLocaleDateString() && element.type === 'entry') {
                        let num = diaryentries.push(element);
                        num -= 1;
                        diaryentries[num]['key'] = num+''; 
                    }else if(new Date(element.timestamp).toLocaleDateString() === new Date(currentDate).toLocaleDateString() && element.type === 'appreciation') {
                        dailyappreciation = element;
                    }else if(new Date(element.timestamp).toLocaleDateString() === new Date(currentDate).toLocaleDateString() && element.type === 'dailyconclusion') {
                        dailyconclusion = element;
                    }
                        //console.log(new Date(element.timestamp).toLocaleDateString());
                });

                days.push({diaryentries: diaryentries, dailyappreciation: dailyappreciation,dailyconclusion: dailyconclusion})
            }
        }

        console.log(days);

        return(
            <>
            <div id="weeklyview">
            <div className="datepicker py-3 d-flex justify-content-center flex-row">
            <Button variant="link" onClick={this.prevWeek}><FontAwesomeIcon icon="arrow-left" /></Button>
            <input ref={this.input} type="week" id="weektoshow" name="weektoshow" onChange={this.handleChange} value={this.state.weekToShow} />
            <span id="fakevalue" style={{left:this.state.fakerleft}}>{monday}-{sunday}</span>
            <Button variant="link" onClick={this.nextWeek}><FontAwesomeIcon icon="arrow-right"  /></Button>
            </div>
            <div className="stats px-4 pt-1 pb-3">
                <p>{monday}-{sunday}</p>
       
            </div>
            </div>
            </>
        );
    }
}

export default WeeklyView;