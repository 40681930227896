import React, {} from 'react';
import { Button } from 'react-bootstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@bkuermayr/ckeditor5-build-sixtimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupDialog from '../dialog/PopupDialog';
import { withRouter } from 'react-router-dom';
import fire from '../../config/Fire';
import { getCurrentTimestamp } from '../../utils/Helper';

class UnfilteredPagesEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showDialog: false,
            isSaved: true,
            user: null,
            postid: null,
            loaded: false,
        }
    }

    componentDidMount() {
        const user = this.props.user;
        let data = this.props.data;
        let editpostid = this.props.match.params.postid;
        if(data) {
           data.forEach((item) => {
                if(item.postid == editpostid) {
                    console.log(item.data);
                    this.setState({
                        data: item.data,
                        postid: item.postid,
                        user: user,
                        loaded: true,
                    },console.log(this.state));
                }
           });
        }else {
            this.setState({
                user:user,
                loaded: true,
            });
        }
    }    

    showSaveDialog = (e) => {
        if(this.state.isSaved) {
            this.goBack();
        }else {
            this.setState({showDialog: true});
        }
    }

    save = (cb) => {
        var str = this.state.data;
        var title = str.match(/<h1>(.*?)<\/h1>/g).map(function(val){
            return val.replace(/<\/?h1>/g,'');
        });

        if(title[0]) {
            title = title[0];
        }
         
        var data = {
            data: this.state.data,
            userid: this.state.user.userId,
            timestamp: getCurrentTimestamp(),
            title: title,
        }
        console.log(data);
        let postid = this.state.postid;
        if(postid) {
            fire.database().ref("/unfilteredpages/"+postid).set(data);
        }else {
            let fb = fire.database().ref("/unfilteredpages").push(data);
            postid = fb.key
        }
        //when saving is done..
        if(typeof cb === "function") {
            cb(true);
        }
        this.setState({ isSaved: true, postid: postid });
        this.goBack(postid);
    }

    hide = (doSave) => {
        this.setState({showDialog: false});
        if(doSave == true) {
            console.log("save")

            this.save(()=> {
                this.goBack();
            });
        }else if(doSave == false) {
            console.log("nosave")

            this.goBack();
        }
    }

    goBack = (postid = this.state.postid) => {
        let id = (postid)?"/"+postid:"";
        this.props.history.push("/unfilteredpages"+id);
    }
    
    render() {
        return (
            <>
            { this.state.loaded &&
                <>
                <PopupDialog showDialog={this.state.showDialog} type='editorgoback' hide={this.hide}/>
                <div className="bg-primary py-2 d-flex profileHeader">
                    <Button className="nostyle" variant="link" onClick={this.showSaveDialog}><FontAwesomeIcon icon="arrow-left" /> <small>Go back</small></Button>
                    <Button className={this.state.isSaved?"ml-auto nostyle disabled":"ml-auto nostyle"} id="saveBtn" variant="link" disabled={this.state.isSaved} onClick={this.save}><FontAwesomeIcon icon="save" /> <small>Save</small></Button>
                </div>             
                   <CKEditor
                    editor={ ClassicEditor }
                    data={ this.state.data }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        document.getElementById("saveBtn").disable="false";
                        this.setState({
                            isSaved: false, 
                            data: data
                        });
                    } }
                    onBlur={ ( event, editor ) => {
                        document.getElementById("unfilteredPages").classList.remove("focused");
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        document.getElementById("unfilteredPages").classList.add("focused");
                        //console.log( 'Focus.', editor );
                    } }
                    uiColor="black"
                />
                </>
                }        
            </>
        );
    }
    
}

export default withRouter(UnfilteredPagesEditor);