import React from 'react';
import fire from '../../config/Fire';
import { Link } from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { loginGoogle } from '../../utils/Helper';

class VibeLogin extends React.Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
          email: '',
          password: '',
          loggingIn: false,
        };

        
      }

      componentDidMount() {
        document.body.classList.add('login');
      }

      componentWillUnmount() {
        document.body.classList.remove('login');
        this.setState({loggingIn: false});
      }
    
      handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }
    
      login(e) {
        e.preventDefault();
        var formEl = e.target;
        formEl.classList.add('loggingin');
        const that = this;
        this.setState({loggingIn: true});
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
        }).then(function(){
          console.log("successfully logged user in");
        }).catch((error) => {

          that.setState({loggingIn: false});
            formEl.classList.remove('loggingin');
            if(error != null) {
              var emailHelp = document.getElementById("emailHelp");
              emailHelp.style.setProperty("color","red","important");
              emailHelp.innerHTML = error.message;
              console.log(error.message);
            }         
        });
      }

    render() {
        return (
          <div className="container-fluid padSect">
            <div className="col-md-6 mx-auto loginSection">
            <h1>Log in to SixTimes</h1>
            <form className="loginForm" onSubmit={this.login}>
           <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input value={this.state.email} onChange={this.handleChange} required="required" type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
           </div>
            <div className="form-group">
           <label htmlFor="passwd">Password</label>
           <input value={this.state.password} onChange={this.handleChange} required="required" type="password" name="password" className="form-control" aria-describedby="passwdHelp" id="passwd" placeholder="Password" />
           <small id="passwdHelp" className="form-text text-muted"></small>
           </div>
           <div className="form-group"> 
            <button type="submit" className="btn btn-primary">
              {this.state.loggingIn === true &&
              <div className="loadingCircleWrapper login ">
                  <CircularProgress className="loadingCircle login" />
              </div>
              }
              Login</button>
           </div>
           <div className="form-group"> 
           <Link to="/signup" className="signupbtn btn btn-primary-outline">Don't have an Account? <u>Sign up</u></Link>
           {false &&<Button onClick={loginGoogle} variant="link" className="mt-1 btn btn-link">Or sign in with Google <i class="fab fa-google"></i></Button>}
           </div>
            </form>
           </div>
          </div>
        );
    }
}

export default VibeLogin;