import React from 'react';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@bkuermayr/ckeditor5-build-sixtimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UnfilteredPagesViewer(props) {
    let postid = useParams().postid;
    let { path, url } = useRouteMatch();
    const data = props.data;
    let content;
    let title;
    if(data) {
        data.forEach((item) => {
             if(item.postid == postid) {
                content = item.data;
                title = item.title;
                console.log(content);
             }
        });
    }

    return (
        <>
        <div className="bg-primary py-2 d-flex profileHeader">
            <Link className="mr-auto nostyle" variant="link"  to="/unfilteredpages"><FontAwesomeIcon icon="arrow-left"/> <small>Go back to overview</small></Link>
            <Link className="ml-auto nostyle" variant="link" to={`/unfilteredpages/edit/${postid}`}><FontAwesomeIcon icon="edit" /> <small>Edit</small></Link>
        </div>      
        <div className="uf-page-viewer">
        <CKEditor
        editor={ ClassicEditor }
        data={ content }
        onInit={ editor => {
            editor.isReadOnly=true;
            // You can store the "editor" and use when it is needed.
            console.log( 'Editor is ready to use!', editor );
        } }
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            //console.log( { event, editor, data } );
            document.getElementById("saveBtn").disable="false";
            this.setState({
                isSaved: false, 
                data: data
            });
        } }
        onBlur={ ( event, editor ) => {
            //console.log( 'Blur.', editor );
        } }
        onFocus={ ( event, editor ) => {
            //console.log( 'Focus.', editor );
        } }
        uiColor="black"
    />
        </div>
        </>
    );
}

export default UnfilteredPagesViewer;
