import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoginRoute = ({component: Component,layout: Layout,isAuth,authUsername,profilePath, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isAuth === false ?
            <Layout isAuth={isAuth} authUsername={authUsername} profilePath={profilePath}>
                <Component {...props} />
            </Layout>
            : <Redirect to="/home" />
        )} />
    );
};

export default LoginRoute;