import React from 'react';
import fire from 'firebase';
import ProfilePicture from './ProfilePicture';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { Button } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loginGoogle } from '../../utils/Helper';

class VibeProfile extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            name:  '',
            user: '',
            email: '',
            dataReceived: false,
            showConfirmDialog: false,
        };

        this.authListener = this.authListener.bind(this);
        this.showConfirmDialog = this.showConfirmDialog.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.authListener();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProfileData(userid,callback) {
        fire.database().ref('/users').child(userid).on('value', function(snap) {
            var data = { 
                name:  snap.val().lastname,
                firstname: snap.val().firstname,
                user: snap.val().username,
                userid: snap.key,
                email: snap.val().email,
                createdAt: snap.val().createdAt,
                dataReceived: true,
            };

            if(callback) {
                callback(data);
            }
        });    
    }

    authListener = () => {
        const that = this;
        fire.auth().onAuthStateChanged((user) => {
            //console.log(user);
            if (user) {
                //console.log(currAuthId);
                that.getProfileData(user.uid,function(data){
                    if(that._isMounted){
                        that.setState(data);
                    }
                });

            } else {
                return false;
            }
        });
    }

    deleteAccount(isConfirmed,password) {
        var user = fire.auth().currentUser; 
        const uid = user.uid;
        const that = this;
        if(isConfirmed) {
                //reauthenticated successfully 
            this.reauthenticate(password).then(function () {
                console.log("Test")
                fire.database().ref("users/"+uid).remove();
                fire.database().ref("profiles/"+uid).remove();

                fire.database().ref("vibes/").orderByChild('userid').equalTo(uid).on('value',function(snapshot){
                    snapshot.forEach(function(snap){
                        fire.database().ref("vibes/"+snap.key).remove();
                    });
                });

                fire.database().ref("replies/").orderByChild('userid').equalTo(uid).on('value',function(snapshot){
                    snapshot.forEach(function(snap){
                        fire.database().ref("replies/"+snap.key).remove();
                    });
                });

                fire.database().ref("likes/").orderByChild('userid').equalTo(uid).on('value',function(snapshot){
                    snapshot.forEach(function(snap){
                        fire.database().ref("likes/"+snap.key).remove();
                    });
                });

                user.delete().then(function() {
                    console.log("Your Account was successfully deleted.");
                    window.location.href="/login";

                });
            }).catch(function(error) {
                that.setState({ showConfirmDialog: true, });
                that.setState({ error: 'Incorrect password, try again.', });        
                console.log("Account could not be deleted. Please try again later. \n"+error);
            });
        }
    }

    reauthenticate = (password) => {
        var user = fire.auth().currentUser;
        console.log(fire.auth())
        var cred = fire.auth.EmailAuthProvider.credential(user.email, password);

        return user.reauthenticateWithCredential(cred);
    }

    showConfirmDialog(){
        this.setState({ showConfirmDialog: true, });
    }

    checkConfirmation = (isConfirmed,password) => {
        this.setState({ showConfirmDialog: false, });
        this.deleteAccount(isConfirmed, password);
    }

    edit = () => {
        console.log("edit");
    }

    render() { 
        return(
            <TransitionGroup>
            { this.state.user && this.state.dataReceived &&
            <CSSTransition classNames="fade" timeout={300}>
            <div className="pt-3 profileWrapper bg-primary text-left">
                <div className="bg-primary pb-3 d-flex profileHeader">
                    <p className="mb-0">Account</p>
                    <Button className="ml-auto nostyle" variant="link" onClick={this.edit}><FontAwesomeIcon icon="edit" /> <small>Edit</small></Button>
                </div>
                <div className="profileContent bg-white">
                    <div className="d-flex justify-content-start pb-2 align-items-center flex-row">
                    <ProfilePicture name={this.state.firstname} size="80" className="rounded-circle mr-2"/>
                    <h1 className="mb-0">{this.state.user}</h1>
                    </div>
                    <p>Name: {this.state.firstname+" "+this.state.name} </p>
                    <p>Email-Address: {this.state.email}</p>
                    <p>User since: {new Date(this.state.createdAt).toLocaleDateString()}</p>
                    <br />
                    <p><Button variant="link" onClick={this.showConfirmDialog}>Delete this Account</Button></p>
                    <ConfirmationDialog error={this.state.error} showDialog={this.state.showConfirmDialog} checkConfirmation={this.checkConfirmation}/> 
                </div>
            </div>
            </CSSTransition>
            }
            </TransitionGroup>
        );
    }
}

export default VibeProfile;