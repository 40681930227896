import React from 'react';
import phone from '../../assets/phone.png';
import SignupForm from '../form/SignupForm';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Home extends React.Component {

    componentDidMount() {
        document.body.classList.add('home');
    }

    componentWillUnmount() {
        document.body.classList.remove('home');
    }

    render() {
        //add body page id 

        return (
            <div className="home-Wrapper">
                <Helmet>
                    <title>SixTimes - Self reflection - Motivation - Personal growth</title>
                </Helmet>
                <div className="container-fluid fullSect bannerSect vh-100 justify-content-center align-items-center d-flex">
                    <div className="row w-100 padSect ">
                        <div className="col-md-6 col">
                            <h4>Introducing SixTimes</h4>
                            <h1><span>The journaling media for personal growth</span></h1>
                            <ul>
                                <li>Keep track of your everyday actions</li>
                                <li>Share your journaling process and motivate others</li>
                                <li>&gt;&gt; Improve your overall life</li>
                            </ul>

                            <Link className="btn-primary btn" to="/signup">Try SixTimes now!</Link>
                        </div>
                        <img className="phoneImg" src={phone} alt="phone with VibeShare app"/>
                    </div>
                </div>
                <div id="concept" className="container-fluid fullSect aboutSect vh-100 justify-content-center align-items-center d-flex">
                <div className="row w-100 padSect justify-content-center align-items-center d-flex ">
                        <div className="col-md-8 text-center">
                            <h3>What is SixTimes and why should I use it?</h3>
                            <p className="big">Journaling is a tool that has been used by the most successful people for thousands of years. It can help you to reach your goals and  it has the potential to greatly improve your life. This journal app was specifically designed and based on buddhist teachings of the "Six times book", one of the most effective ways of journaling.</p>
                            <div className="d-flex flex-column justify-content-center align-items-center font-weight-bold text-primary">
                                <a href="#signup">Start your journaling journey now</a>
                                <FontAwesomeIcon className="arrow-down" icon="arrow-down"/>
                            </div>
                        </div>
                    </div>                
                </div>
                <div id="signup" className="container-fluid fullSect  signupSect justify-content-center align-items-center d-flex">
                <div className="row w-100 padSect justify-content-center align-items-center py-4 d-flex ">
                        <div className="col-md-6 text-center">
                            <h3 className="py-4 text-light">Sign up for SixTimes</h3>
                            <SignupForm />
                        </div>
                    </div>                
                </div>
            </div>
        );
    }
}

export default Home;