import fire from '../config/Fire';
import firebase from 'firebase';

export function getCurrentTimestamp() {
    var now     = new Date(); 
    var year    = now.getFullYear();
    var month   = now.getMonth(); 
    var day     = now.getDate();
    var hour    = now.getHours();
    var minute  = now.getMinutes();
    var second  = now.getSeconds(); 
    if(month.toString().length === 1) {
         month = '0'+month;
    }
    if(day.toString().length === 1) {
         day = '0'+day;
    }   
    if(hour.toString().length === 1) {
         hour = '0'+hour;
    }
    if(minute.toString().length === 1) {
         minute = '0'+minute;
    }
    if(second.toString().length === 1) {
         second = '0'+second;
    }   
    var isoDateTime =new Date(year, month, day, hour, minute, second).toISOString();
    return isoDateTime;
}

export function getCurrentUserId(authid,callback) {
    fire.database().ref('/users').orderByChild('authUid').equalTo(authid).on('value', function(snapshot) {
        snapshot.forEach(function(snap) {
            var data = snap.key;
            if(callback) {
                callback(data);
            }
        });
    });     
}

export function getPostUsername(userid,callback) {
    //console.log(userid)
    fire.database().ref('/profiles').child(userid).on('value', function(snapshot) {
        if(snapshot.val() !== null) {
            var data = {
                username: snapshot.val().username,
                firstname: snapshot.val().firstname,
                lastname: snapshot.val().lastname,
            };
            //console.log(data);
            if(callback) {
                callback(data);
            }
        }else {
            callback(null);
        }
    });      
}

export function loginGoogle(cb) {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithPopup(provider).then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var credential = result.credential;
        console.log(user);
        var name = user.displayName.split(user.displayName.lastIndexOf(" "),2);
        var username = user.email.split("@",2)[0];
        writeUserData(username,name[0], name[1],user.email,user.uid);
        cb(result)
        // ...
    }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ..
        cb(error)
    });
}

export function writeUserData(uname, firstname, lastname, email,userid) {
    var timestamp = getCurrentTimestamp();
    fire.database().ref('users/'+userid).set({
        username: uname,
        email: email,
        lastname: lastname,
        firstname: firstname,
        createdAt: timestamp
    }).then(function() {
      console.log("successfully wrote private user data to database");
    }).catch(function(error) {
      console.log(error.message);
    });

    //write public userdata to db
    fire.database().ref('profiles/'+userid).set({
        username: uname,
        firstname: firstname,
        lastname: lastname
    }).then(function() {
      console.log("successfully wrote public user data to database");
    }).catch(function(error) {
      console.log(error.message);
    });
  }

export function convertTimestamp(timestamp) {
    var timeDate = Math.abs(new Date() - new Date(timestamp)) / 1000;
    var days = Math.floor(timeDate / 86400);
    var hours = Math.floor(timeDate / 3600) % 24;        
    var min = Math.floor(timeDate / 60) % 60;
    var secs = Math.floor(timeDate % 60);

    var res = "";
    if(days <= 14) {
        if(days > 0) {
            var s = (days === 1)?"":"s";
            res = days + " day"+s+" ago";
        }else {
            if(hours > 0) {
                s = (hours === 1)?"":"s";
                res = hours + " hour"+s+" ago";
            } else {
                if(min > 0) {
                    s = (min === 1)?"":"s";
                    res = min + " minute"+s+" ago";
                }else {
                    s = (secs === 1)?"":"s";
                    res = secs + " second"+s+" ago";
                }
            }
        }

    } else {
        res = new Date(timestamp).toLocaleDateString();
    }

    return res;
}

export function getLikes(postid,cb) {
    //get likes for post
    fire.database().ref('/likes').orderByChild('postid').equalTo(postid).on('value',function(snapshot){
        cb(snapshot.val());
    });
}

export function getUnfilteredPages(userid,cb) {
    fire.database().ref("/unfilteredpages").orderByChild("userid").equalTo(userid).on('value',function(snapshot){
        var rawdata = snapshot.val();
        if(rawdata !== null) {
            var arr = [];
            for (var key of Object.keys(rawdata)) {
                var line=rawdata[key];
                line['postid'] = key;
                arr.push(line);
            }
            
            return cb(arr);
        } else {
            return cb(new Array(0));
        }
    });
}

export function getEntries(uid,cb) {
    fire.database().ref('/vibes').orderByChild('userid').equalTo(uid).on('value',function(snapshot){
        var rawdata = snapshot.val();
        if(rawdata !== null) {
            var arr = [];
            for (var key of Object.keys(rawdata)) {
                var line=rawdata[key];
                line['postid'] = key;
                arr.push(line);
                
            }
    
            cb(arr);
        } else {
            cb(new Array(0));
        }


    });
}


export function getEntry(uid, postid,cb) {
    fire.database().ref('/vibes').orderByChild('userid').equalTo(uid).on('value',function(snapshot){
        var rawdata = snapshot.val();
        if(rawdata !== null) {
            var entry;
            for (var key of Object.keys(rawdata)) {
                if(key === postid) {
                    var line=rawdata[key];
                    line['postid'] = key;
                    entry = line;
                }
            }
    
            cb(entry);
        } else {
            cb(false);
        }


    });
}

export function parseData(data){
    var rawdata = "";
    if(data != null) {
        rawdata = data;
    }

    var arr = [];
    for (var key of Object.keys(rawdata)) {
        var line=rawdata[key];
        line['key'] = key;
        line['postid'] = key;
        if(line['type'] === 'entry') {
            var content = line['content'];
            line['content'] = [];
            line['content'].push(content['positiveaction'],content['negativeaction'],content['todo']);

        }else if(line['type'] === 'dailyconclusion') {
            var content = line['content'];
            console.log(content);
            line['content'] = [];
            var pos = [];
            var neg = [];
            for(let i = 0; i < content.positiveactions.length; i++) {
            pos.push(content.positiveactions[i].label);
            }
            for(let i = 0; i < content.negativeactions.length; i++) {
            neg.push(content.negativeactions[i].label);
            }

            line['content'].push(pos,neg,content['todo']);
        }

        if(line !== null) {
            arr.push(line);
        }
    }
    arr.reverse();
    return arr;
}

export function typeName(type) {
    var typeName = '';
    if(type != null) {
        if(type === 'entry') {
            typeName = 'Diary entry';
        }else if(type === 'appreciation') {
            typeName = 'Daily appreciation';
        }else if(type === 'dailyconclusion') {
            typeName = 'Daily conclusion';
        }
    }

    return typeName;
}

export function entryTitle(key) {
    var name = '';
    if(key !== null) {
        switch(key) {
            case '0':
                name = 'Entry no. 1';
                break;
            case '1':
                name = 'Entry no. 2';
                break;
            case '2':
                name = 'Entry no. 3';
                break;
            case '3':
                name = 'Entry no. 4'; 
                break;
            case '4':
                name = 'Entry no. 5';
                break;
            case '5':
                name = 'Entry no. 6';
                break;
            default:
                name = 'Entry';
        }
    }

    return name;
}

export function getCookie(name){
        var visited;
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) visited = ( c.substring(nameEQ.length,c.length));
        }
        return visited;
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function getWeek(d) {
    console.log(d);
    var date = new Date(d);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    var weeknr = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
    var yearnr = date.getFullYear();

    return yearnr + "-W" + weeknr;
}
  
export function getDateOfISOWeek(d) {
    d = d.split("-W",2);
    var w = d[1];
    var y = d[0];
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export function getExcerpt(content, wordlimit) {
    var filter = content.replace(/\s+/g, ' ');
    var wordsarr = filter.split(' ');
    if(wordsarr.length < wordlimit) {
        return content;
    }else {
        var result = "";    
        for (var i = 0; i < wordlimit; i++) {
            result = result + " " + wordsarr[i] + " ";
        }
        return result;
    }
}

export function getTimeData(userid,cb) {
    fire.database().ref(`/timetracking/${userid}`).orderByChild('timestamp').on('value',function(snapshot){

        var rawdata = snapshot.val();
        var data = [];
        Object.keys(rawdata).forEach((key, i) => {
            data.push(rawdata[key])
        });
        data.reverse()
        console.log(data);
        cb(data);
    });
}

export function logout() {
    fire.auth().signOut();
    window.location.href = "/";
}

    
    