import React, { Component } from 'react'; 
import ad from '../../assets/diamondcutter.png';
import banner from '../../assets/brand_banner.png';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class AdSpace extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showAd: true
        }
    }

    hideAd = () => {
        var cookieDate = new Date()
        cookieDate.setFullYear(cookieDate.getFullYear() + 1)
        this.setState({ showAd: false })
        cookies.set('hideAd', 'true', { path: '/', expires: cookieDate.toUTCString() });
    }

    showAd = () => {
        this.setState({ showAd: true })
        cookies.remove('hideAd');
    }

    componentDidMount() { 
        if (cookies.get('hideAd')) {
            this.setState({ showAd: false });
        }
    }

    render() {
        return(
            <div className={"adSpace " + this.state.showAd}>
                { this.state.showAd &&
                    <>
                    <Button variant="outline" onClick={this.hideAd} className="mt-2 close-ad"><FontAwesomeIcon icon="window-close"/></Button>
                    <a href="https://www.thalia.at/shop/home/artikeldetails/ID16904981.html" target="_blank" rel="noopener noreferrer" title="the diamond cutter book - buy now">
                        <img src={ad} alt="the diamond cutter book - buy now" />
                    </a>
                    </>
                } { !this.state.showAd &&
                    <>
                        <Button variant="outline" onClick={this.showAd} className="mt-2 close-ad"><FontAwesomeIcon icon="caret-square-left"/></Button>
                        <img src={banner} alt="sixtimes - improve your overall life" />
                    </>
                }
            </div>
        );
    }
}