import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

export const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[500],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
})(Switch);
    