import React from 'react';
import { Button,Nav,Navbar } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { logout } from '../../utils/Helper'

export default function VibeHeader(props) {
    var buttonLeft, buttonRight;
    console.log(props)

    if(props.user.auth === false) {
        buttonLeft = <Button variant="link"><Link to="/login">Log in</Link></Button>;
        buttonRight = <Button variant="outline-primary"><Link to="/signup">Sign up</Link></Button>;
    }else if(props.user.auth === true) {
        buttonLeft = <Button variant="link" onClick={logout}><FontAwesomeIcon icon="sign-out-alt" /> Log out</Button>;
        if (props.hideBtnRight === false) {
            buttonRight = <Button variant="link" className="d-flex justify-content-start align-items-center flex-row"><Link className="btn-link" to={props.profilePath}><FontAwesomeIcon icon="user" />  Account</Link></Button>;
        }
    }
    return (
        <Navbar bg="light" expand="md" className="App-Header">
        <Navbar.Brand><Link to="/"><img src={logo} alt="vibeshare logo" height="40px" className="brandImg"/></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            {buttonLeft}
            {buttonRight}
        </Navbar.Collapse>
        </Navbar>
    );
}