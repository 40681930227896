import React, { Component } from 'react'
import fire from '../../config/Fire';
import { getCurrentTimestamp } from '../../utils/Helper';

class TimeTrackForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            lastTracker: null,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.setState({lastTracker: new Date(this.props.lastTracker.timestamp).toLocaleTimeString()})
    }

    componentDidUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.setState({lastTracker: new Date(nextProps.lastTracker.timestamp).toLocaleTimeString()})
        }
    }

    postTracker = (e) => {
        e.preventDefault();

        var data = {
            content: this.state.content,
            timestamp: getCurrentTimestamp(),
            userid: this.props.userid,
        }

        fire.database().ref('timetracking/'+data.userid+'/').push(data).then(function() {
            console.log("successfully wrote timetracker to database");
            console.log(data)
        }).catch(function(error) {
          console.log(error.message);
        });
        this.reinit()
    }

    reinit() {
        this.setState({ content: '' })
        this.props.updateData()
    }
    
    render() {
        return (
            <form id="trackerForm" onSubmit={this.postTracker}>
                {/*<input type="time" id="appt" name="appt" min={this.state.lastTracker} max={new Date(getCurrentTimestamp()).toLocaleTimeString()} required/>*/}
                <textarea onChange={this.handleChange} required value={this.state.content} name="content" id="contentInput" placeholder={'What have you been doing between the last tracker at '+this.state.lastTracker+' and now ('+new Date(getCurrentTimestamp()).toLocaleTimeString()+'?'}></textarea>
                <input className="btn-primary btn" type="submit" value="Add tracker" />
            </form>
        )
    }
}

export default TimeTrackForm