import React from 'react';
import fire from '../../config/Fire';
import { Button } from 'react-bootstrap';
import { getCurrentTimestamp } from '../../utils/Helper';
import { PurpleSwitch } from '../form/PurpleSwitch';
import { Link } from 'react-router-dom';
import {UserContext} from '../../utils/UserContext';

class EntryCreateForm extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            positiveaction: '',
            negativeaction: '',
            todo: '',
            public: false,
            userid: null,
            appreciationDone: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleChecked = this.toggleChecked.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        const user = this.context;
        const stats = this.props.stats;
        console.log(stats);
        var tips = [
            "Try to always keep it short and simple, for this journal to be most effective. There is no need to write entire and correct sentences (e.g. by using abbreviations)",
            "When posting we recommend that you do only use first names or shorten names of people for anonymity (e.g. \"C.\" or \"Carla\" instead of \"my friend Carla Müller\")",
            "Try to evenly distribute your entries throughout the day, for this journal to be most effective. (e.g. an entry every two hours)",
        ];
        var tip = tips[Math.floor(Math.random()*3)];

        this.setState({
            tip: tip,
            appreciationDone: stats.appreciationDone,
            dailyentriesnumber: stats.dailyentriesnumber,
            userid: user.userId
        });
    }

    postEntry = (e) => {
        e.preventDefault();

        var entryData = {
            userid: this.state.userid,
            timestamp: getCurrentTimestamp(),
            content: {
                positiveaction: this.state.positiveaction,
                negativeaction: this.state.negativeaction,
                todo: this.state.todo
            },
            public: this.state.public,
            type: 'entry'
        };

        fire.database().ref('vibes/').push(entryData)
        .then(function() {
          console.log("successfully wrote entry data to database");
        }).catch(function(error) {
          console.log(error.message);
        });

        this.reinit();
        this.props.hideCallback();
        window.location.reload();
    }

    reinit() {
        this.setState({
            positiveaction: '',
            negativeaction: '',
            todo: '',
            public: true,
        });
    }

    toggleChecked = () => {
        const that = this;
        this.setState({ public: !that.state.public });
    }

    render() {
        return(
            <div className="entryFormWrapper">
                { this.state.appreciationDone !== null && this.state.dailyentriesnumber < 6 && 
                <form className="entryForm" onSubmit={this.postEntry}>
                    <div className="w-100 row buttonWrap d-flex align-items-start justify-content-end flex-column">
                    <label htmlFor="positiveaction"><h5>Positive action</h5></label>
                    <textarea onChange={this.handleChange} rows="2" required value={this.state.positiveaction} name="positiveaction" id="positiveaction" placeholder="What did you do, that planted a positive imprint in your mind?"></textarea><br/>
                    <label htmlFor="negativeaction"><h5>Negative action</h5></label>
                    <textarea onChange={this.handleChange} rows="2" required value={this.state.negativeaction} name="negativeaction" id="negativeaction" placeholder="What did you do, that planted a negative imprint in your mind?"></textarea><br/>
                    <label htmlFor="todo"><h5>Todo</h5></label>
                    <textarea onChange={this.handleChange} rows="2" required value={this.state.todo} name="todo" id="todo" placeholder="How can you improve in the future, regarding your recent actions?"></textarea><br/>
                    <small className="font-italic mt-2">{this.state.tip}</small>
                    <label className="mt-2">
                        Public
                        <PurpleSwitch size="small" checked={this.state.public} onChange={this.toggleChecked} />
                    </label>
                    <Button type="submit" className="mt-1 w-100" variant="primary">Add entry</Button>
                    </div>
                </form>  
                }
                { this.state.appreciationDone !== null && this.state.dailyentriesnumber === 6 && 
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h5>Congratulations!</h5>
                    <p>You already wrote your 6 entries today.</p>
                    <Link to="/diary" className="btn-primary btn">Show in diary</Link>
                </div>                
                }

            </div>
        );
    }
}

export default EntryCreateForm;