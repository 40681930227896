import React from 'react';
import { getEntries } from '../../utils/Helper';
import { parseData } from '../../utils/Helper';
import { NavLink, Switch, Route, BrowserRouter } from 'react-router-dom';
import DailyView from '../calender/daily/DailyView';
import MonthlyView from '../calender/monthly/MonthlyView';
import WeeklyView from '../calender/weekly/WeeklyView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntryContext } from '../../utils/EntryContext';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button } from 'react-bootstrap';

export default class VibeDiary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: null,
        }
    }

    componentDidMount() {
        const that = this;
        getEntries(that.props.userid, function(entries){
            that.setState({
                entries: parseData(entries).reverse(),
                currentdate: that.getDate()
            });
        });
    }

    getDate() {
        let date = new Date();
        let y = date.getFullYear();
        let m = (date.getMonth() < 10) ? '0' + date.getMonth() : date.getMonth();
        let d = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();

        return y + "/" + m + "/" + d;
    }

    render() {
        console.log(this.state.entries)
        return(
            <TransitionGroup component={null}>
            { this.state.entries !== null && 
            <CSSTransition classNames="fade" timeout={300}>
            <div className="vibeDiary">
                <div className="bg-primary py-2 d-flex profileHeader">
                    <p className="m-0">Diary</p>
                </div>  
                {/*
                <div className="row w-100 d-flex justify-content-center mt-4 diaryLinks">
                <NavLink to="/diary/daily" activeClassName="active" className="btn btn-primary"><FontAwesomeIcon icon="calendar-day" /> Daily</NavLink>
                <NavLink to="/diary/weekly" activeClassName="active" className="btn btn-primary"><FontAwesomeIcon icon="calendar-week" /> Weekly</NavLink>
                <NavLink to="/diary/monthly" activeClassName="active" className="btn btn-primary"><FontAwesomeIcon icon={{prefix:"far",iconName:"calendar-alt"}} /> Monthly</NavLink>
                </div>
                */}
                <div className="px-3">
                { this.state.entries !== null &&
                <EntryContext.Provider value={this.state.entries}>
                    <Route exact path="/diary/" component={DailyView} />
                    <Route exact path="/diary/daily" component={DailyView} />
                    <Route exact path="/diary/weekly" component={WeeklyView} />
                    <Route exact path="/diary/monthly" component={MonthlyView} />
                </EntryContext.Provider>
                }
                </div>
            </div>
            </CSSTransition>}
            </TransitionGroup>
        );
    }
}