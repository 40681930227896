import React from 'react';
import fire from '../../config/Fire';
import ReplyForm from '../reply/ReplyForm';
import ReplyFeed from '../reply/ReplyFeed';
import {getPostUsername, convertTimestamp, getLikes, typeName, getEntry } from '../../utils/Helper';
import { Button } from 'react-bootstrap';
import ProfilePicture from '../profile/ProfilePicture';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const LikeHeart = ({ filled, onClick }) => {

    return (
        <FontAwesomeIcon onClick={onClick}  icon={filled ? 'heart':['far','heart']}/>
    );
};

  
class VibePost extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            postid: null,
            showForm: false,
            commmentNumber: '0',
            likeNumber: '0',
            filled: false,
            loaded: false,
            type: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const that = this; 

        if(that.props.type === "singlepost") {
            getEntry(that.props.userid,);
        }
        
        getPostUsername(that.props.userid,function(data){
            console.log(that.props.userid);
            if(that._isMounted) {
                var userdata;
                console.log(that.props.public);
                if(that.props.public === false) {
                    userdata = {
                        username: null,
                        name: 'Anonymous user',
                        postid: that.props.postid,
                        timestamp: convertTimestamp(that.props.timestamp),    
                    };
                }else {
                    userdata = {
                        username: '@'+data.username,
                        name: data.firstname,
                        postid: that.props.postid,
                        timestamp: convertTimestamp(that.props.timestamp),
                    };
                }
                
                that.setState(userdata,function(){

                    if(that.state.postid !== null) {
                    //set likes and filled state
                        getLikes(this.state.postid,function(likes){
                            var likeNumber = 0;
                            if(likes != null) {
                                var arr = [];
                                for (var key of Object.keys(likes)) {
                                    var line=likes[key];
                                    arr.push(line);

                                    //if loggedin user already liked fill heart
                                    if(line.userid === that.props.authid && that._isMounted) {
                                        that.setState({filled: true });
                                    }
                                }
                    
                                likeNumber = arr.length;
                            }
                            if(that._isMounted) {
                                that.setState({
                                    likeNumber: likeNumber,
                                    loaded: true,
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    cancelReplyCallback = () => {
        this.setState({
            showForm: false,
        }); 
    }

    setCommentNumber = (number) => {
        this.setState({ commmentNumber: number });
    }

    likePost = (e) => {
        const that = this;

        this.setState({ filled: !that.state.filled },function(){
            var filled = that.state.filled;

            //if just liked, save like in db
            if(filled) {
                var data = {
                    postid: that.state.postid,
                    userid: that.props.authid,
                };

                fire.database().ref('/likes').push(data,(err) => {
                    console.log("save like in db");
                });


                
            }else if(filled === false) {
                //if not liked 
                getLikes(this.state.postid,function(likes){
                    if(likes !== null) {
                        for (var key of Object.keys(likes)) {
                            var line=likes[key];
                            if(line.userid === that.props.authid && that.state.filled === false) {
                                fire.database().ref('/likes/'+key).remove();
                                console.log("remove like in db");
                            }
                        }
                    }
                });

            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {        
        var type = typeName(this.props.type);
        
        return (
            <TransitionGroup component={null}>
            { this.props.public === true && this.state.loaded &&
                <CSSTransition classNames="fade" timeout={0}>
                <div className="vibePost col-12" id={this.state.postid}>
                    <div className="vibePostInner">  
                        { this.props.type != null &&
                            <span className={ this.props.type + " type-marker"}>{type}</span>
                        }
                        <div className="d-flex justify-content-start pt-4 pb-3 align-items-center flex-row">
                            <ProfilePicture size="50" name={this.state.name} className="rounded-circle mr-2"/>
                            <div className="d-flex flex-column">
                                <div className="vibeUser"><b>{this.state.name}</b> {/*this.state.username !==*/false && this.state.username}</div>
                                <div className="vibeDate">{this.state.timestamp},
                                <span className="comment ml-2">{this.state.commmentNumber}&nbsp;<FontAwesomeIcon icon='comment' /></span><span className="ml-2 heart">{this.state.likeNumber}&nbsp;<FontAwesomeIcon icon='heart' /></span>
                                </div>       
                            </div>
                        </div>
                        { this.props.type === 'appreciation' &&
                        <p className="vibeText">{this.props.content}</p>
                        }
                        { this.props.type === 'entry' &&
                        <div className="vibeEntry">
                        <p className="vibeText positiveaction">
                            <FontAwesomeIcon title="positive action" icon="plus-circle"/> {this.props.content[0]}
                        </p>
                        <p className="vibeText negativeaction">
                            <FontAwesomeIcon title="negative action" icon="minus-circle"/> {this.props.content[1]}
                        </p>
                        <p className="vibeText todo">
                            <FontAwesomeIcon title="ToDo" icon="list-ul"/> {this.props.content[2]}
                        </p>
                        </div>
                        }
                        { this.props.type === 'dailyconclusion' &&
                            <div className="vibeEntry">
                            { this.props.content[0].map(item =>
                            <p className="vibeText positiveaction">
                                <FontAwesomeIcon title="positive action" icon="plus-circle"/> {item}
                            </p>
                            ) 
                            }

                            { this.props.content[1].map(item =>
                            <p className="vibeText negativeaction">
                                <FontAwesomeIcon title="negative action" icon="minus-circle"/> {item}
                            </p>
                            )
                            }
                            <p className="vibeText todo">
                                <FontAwesomeIcon title="ToDo" icon="list-ul"/> {this.props.content[2]}
                            </p>
                            </div>
                        }
                        <div className="postinfo d-flex justify-content-start py-2 align-items-center flex-row">
                        </div>
                        <div className="w-100 row likebuttonWrap">
                        <Button onClick={this.likePost} variant="link" className={this.state.filled?"filled btn btn-link ml-auto like-btn":"btn btn-link ml-auto like-btn"}><LikeHeart filled={this.state.filled} onClick={this.likePost} /> Like</Button>
                        </div>
                        <div className="commentSection pt-4">
                        <ReplyForm postid={this.state.postid} userid={this.props.userid} authUsername={this.props.authUsername}></ReplyForm> 
                        <ReplyFeed postid={this.state.postid} setCommentNumber={this.setCommentNumber} userid={this.props.userid} authUsername={this.props.authUsername} ></ReplyFeed>
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
            </TransitionGroup>
        );    

    }
}

export default VibePost;
