import React, { useContext } from 'react'
import DefaultLayout from '../layout/DefaultLayout';
import { UserContext } from '../../utils/UserContext'
import { Button } from 'react-bootstrap'

function VerifyEmail() {
    const user = useContext(UserContext)
    var answer = "";
    const send = () => {
        try {
            user.fbuser.sendEmailVerification();
        } catch (err) {
            answer = err.message
        }
    };
    var status;
    if (user.emailVerified) {
       status = <span style={{color: 'green'}}>Successfully verify Email</span> 
    } else {
        status = <span style={{color: 'red'}}>Waiting for verification</span> 
    }
    return (
        <DefaultLayout hideBtnRight={true}>
            <div className="padSect pt-4" style={{minHeight:'100vh'}}>
                <h1 className="mt-4">Please check your inbox</h1>
                <p>We have sent you an Email to {user.email}. Please click the verification link in the Email and <u>refresh</u> this page after.</p>
                <p>Live Verification Status: {status}</p>
                <p>Did not receive an Email? <Button variant="link" onClick={send}>Resend verification Email</Button></p>
                <small>{answer}</small>
            </div>
        </DefaultLayout>
    )
}

export default VerifyEmail
