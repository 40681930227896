import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component,layout: Layout,isAuth,authUsername,name,profilePath,userid, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isAuth === true ?
            <Layout isAuth={isAuth} authUsername={authUsername} userid={userid} name={name} profilePath={profilePath}>
                <Component {...props} authUsername={authUsername} userid={userid}/>
            </Layout>
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;