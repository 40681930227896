import React, { useState, useEffect } from 'react'
import TimeTrackForm from '../form/TimeTrackForm'
import { Timeline, Event } from "react-timeline-scribble";
import { getTimeData, getCurrentTimestamp } from "../../utils/Helper";
    
function TimeTracker(props) {
    const [data, setData] = useState(null)
    useEffect(() => {
        getTimeData(props.userid, (data) => {
            setData(data)
        })
    }, [props])

    const updateData = () => {
        getTimeData(props.userid, (data) => {
            setData(data)
        })
    }

    return (
        <div className="timetracker">
            <div className="bg-primary py-2 d-flex profileHeader">
                <p className="m-0">Time Tracking</p>
            </div>  
            { data && 
            <>
                <div class="p-4">
                    <TimeTrackForm userid={props.userid} updateData={updateData} lastTracker={data[0]}/>
                </div>
                <div class="p-4 timefeed">
                    <Timeline>
                        { Object.keys(data).map((key,i) => (
                            <Event interval={new Date(data[key].timestamp).toLocaleTimeString()} title={''} key={key}>
                                {data[key].content}
                            </Event>
                        ))}
                    </Timeline>
                </div>
            </>
            }
        </div>
    )
}

export default TimeTracker
