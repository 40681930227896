import React from 'react';
import { Button } from 'react-bootstrap';
import fire from '../../config/Fire';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getCurrentTimestamp } from '../../utils/Helper';

class ReplyForm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            replyContent: '',
            displaySubmit: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.postReply = this.postReply.bind(this);
    }

    handleChange(e) {
        if(this.state.replyContent.length >= 1) {
            this.setState({ displaySubmit: true });
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;
        this.getAuthId(function(authid){
            if(that._isMounted) {
                that.setState({
                    authid: authid,
                },that.getName(authid,function(name){
                    that.setState({authName: name});
                }));
            }
        });
    }

    componentWillUnmount() {
        this._isMounted  = false;
    }

    getAuthId(cb){
        fire.auth().onAuthStateChanged((fbuser) => {
            if (fbuser) {
                cb(fbuser.uid);
            }else {
                cb(null);
            }
        });
    }

    getName(userid,callback) {
        //console.log(userid)
        fire.database().ref('/profiles').child(userid).on('value', function(snapshot) {
            if(snapshot.val() !== null) {
                var data = {
                    username: snapshot.val().username,
                    firstname: snapshot.val().firstname,
                    lastname: snapshot.val().lastname,
                };
                //console.log(data);
                if(callback) {
                    callback(data.name);
                }
            }else {
                callback(null);
            }

        });     
    }

    postReply = (e) => {
        e.preventDefault();
        e.target.querySelector('#replyContent').value ="";
        const that = this;

        var replyData = {
            userid: this.state.authid,
            replyToPost: that.props.postid,
            replyContent: that.state.replyContent,
            timestamp: getCurrentTimestamp(),
        };

        //var newReplyId = fire.database().ref().child('replies').push().key;

        fire.database().ref('replies/').push(replyData)
        .then(function() {
          console.log("successfully wrote reply data to database");
        }).catch(function(error) {
          console.log(error.message);
        });
    }

    render() {
        return ( 

            <div className="replyFormWrapper">
            <form className="replyForm" onSubmit={this.postReply}>
                <input type="text" onChange={this.handleChange} className="text-italic w-100 commentInput" required name="replyContent" id="replyContent" placeholder={"Leave a comment as "+this.props.authUsername}/>
                <div className="w-100 row buttonWrap d-flex align-items-center flex-row">
                <TransitionGroup component={null}>
                { this.state.displaySubmit &&
                <CSSTransition classNames="fade" timeout={10}>
                    <>
                        <p>Leave a respectful and motivating comment :-)</p>
                        <Button type="submit" className="ml-auto commentBtn" variant="primary">Comment</Button>
                    </>
                </CSSTransition>
                }
                </TransitionGroup>
                </div>
            </form>  
            </div>

        );
    }
}

export default ReplyForm;