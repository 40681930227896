import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.scss';
import './fonts/forte.ttf'
import App from './App';
//import 'bootstrap/dist/css/bootstrap.min.css';
if (process.env.NODE_ENV !== "development")
    console.log = () => {};

ReactDOM.render(<App />, document.getElementById('root'));
