import React, { Component } from 'react';
import Home from './components/pages/Home';
import Imprint from './components/pages/Imprint';
import VibeShare from './components/layout/VibeShare';
import VibeLogin from './components/form/VibeLogin';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { UserContext } from './utils/UserContext';
import VibeSignup from './components/pages/VibeSignup';
import VibeProfile from './components/profile/VibeProfile';
import fire from './config/Fire';
import logo from './assets/logo_light.png';
import logo_dark from './assets/logo.png';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import SignupRoute from './utils/SignupRoute';
import LoginRoute from './utils/LoginRoute';
import CircularProgress from '@material-ui/core/CircularProgress';
import VibeShareHome from './components/pages/VibeShareHome';
import DefaultLayout from './components/layout/DefaultLayout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faHome, faUser, faComments, faBook, faComment, faBell,faFile, faSignOutAlt,faCaretSquareLeft,faClock, faHeart, faFeather, faCheckCircle, faPlusCircle, faMinusCircle, faListUl,faWindowClose, faArrowDown, faEdit,faRedo, faCalendarDay, faCalendarWeek, faArrowLeft, faArrowRight, faSave, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart, faGrin, faAngry, faFrown, faMeh, faSmile, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import VibeDiary from './components/pages/VibeDiary';
import { writeUserData } from './utils/Helper';
import UnfilteredPages from './components/pages/UnfilteredPages';
import TimeTracker from './components/pages/TimeTracker';
import VerifyEmail from './components/pages/VerifyEmail';

library.add(faCheckSquare, faHome,faUser, faBook, faComments, faComment, faBell,faCaretSquareLeft, faSignOutAlt,faHeart, farHeart, faClock,faGrin, faAngry, faFrown, faMeh, faSmile, faFeather, faCheckCircle,faPlusCircle, faMinusCircle, faListUl,faWindowClose, faArrowDown, faFile, faEdit,faRedo, faCalendarDay, faCalendarWeek, faCalendarAlt,faArrowLeft, faArrowRight, faSave, faEllipsisV );

class App extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      auth: null,
      profilePath: null,
      loaded: false,
      isAdmin: false,
    });

    this.authListener = this.authListener.bind(this);
  }

  componentDidMount() {
    const that = this;
    that.authListener(function(data) {
      that.setState(data,function() {
        setTimeout(function(){
        that.setState({loaded: true})
        }, 0);
        console.log("isAuth:"+that.state.auth);
        console.log("profilePath:"+that.state.profilePath);    
        console.log(that.state);

      });
 

    });

  }

  authListener(cb) {
    const that =  this;
    fire.auth().onAuthStateChanged((fbuser) => {
      if (fbuser) {
          that.setState({ emailVerified: fbuser.emailVerified, email: fbuser.email, fbuser: fbuser });
          that.getProfileData(fbuser.uid,function(userdata){
            console.log(fbuser);
            if(userdata !== null) {
              cb(userdata);
            }else {
              var user = fbuser;
              var name = user.displayName.split(" ",2);
              var username = user.email.split("@",2)[0];
              writeUserData(username,name[0], name[1],user.email,user.uid);
              
              that.getProfileData(fbuser.uid,function(data){
                cb(data);
              });
            }
          });
        }else {
          var data = {auth: false};
          cb(data);
        }
    });
  }

  getProfileData(userid,callback) {
      fire.database().ref('/users').child(userid).on('value', function(snapshot) {
        if(snapshot.val() !== null) {
          var data = {
            username: snapshot.val().username,
            firstname: snapshot.val().firstname,
            lastname: snapshot.val().lastname,
            userId: snapshot.key,
            auth: true,
            profilePath: "/" + snapshot.val().username,
            isAdmin: snapshot.val().isAdmin || false,
          };

          callback(data);
        }else {
          callback(null);
        }
      });          
  }

  render() {
    return (
      <div className="App">
        <div className="App-main">
          {this.state.loaded && 
          <BrowserRouter>
            <Switch>
              <UserContext.Provider value={this.state}>
                <SignupRoute isAuth={this.state.auth} profilePath={this.state.profilePath} authUsername={this.state.username}  exact path="/" layout={DefaultLayout} component={ Home } />
                <PublicRoute isAuth={this.state.auth} profilePath={this.state.profilePath} authUsername={this.state.username}  exact path="/about" layout={DefaultLayout} component={ Home } />
                <PublicRoute isAuth={this.state.auth} profilePath={this.state.profilePath} authUsername={this.state.username}  exact path="/imprint" layout={DefaultLayout} component={ Imprint } />
                <SignupRoute isAuth={this.state.auth} profilePath={this.state.profilePath} authUsername={this.state.username} layout={DefaultLayout} exact path="/signup" component={ VibeSignup } />
                <LoginRoute isAuth={this.state.auth} profilePath={this.state.profilePath} authUsername={this.state.username}  layout={DefaultLayout} exact path="/login" component={ VibeLogin } />
                {this.state.emailVerified &&
                <>
                <PrivateRoute isAuth={this.state.auth} profilePath={this.state.profilePath} name={this.state.name} authUsername={this.state.username} layout={VibeShare} path="/unfilteredpages" component={ UnfilteredPages } />
                <PrivateRoute isAuth={this.state.auth} profilePath={this.state.profilePath} name={this.state.name} authUsername={this.state.username} layout={VibeShare} exact path={this.state.profilePath?this.state.profilePath:"profile"} component={ VibeProfile } />
                <PrivateRoute isAuth={this.state.auth} profilePath={this.state.profilePath} name={this.state.name} authUsername={this.state.username} layout={VibeShare}  exact path="/home" component={ VibeShareHome } />
                <PrivateRoute isAuth={this.state.auth} userid={this.state.userId} profilePath={this.state.profilePath} name={this.state.name} authUsername={this.state.username} layout={VibeShare} path={"/diary"} component={VibeDiary} />
                </>
                }
                { this.state.emailVerified === false &&
                  // verify email page
                    <VerifyEmail />
                }
                {this.state.isAdmin &&
                  <PrivateRoute isAuth={this.state.auth} profilePath={this.state.profilePath} name={this.state.name} authUsername={this.state.username} userid={this.state.userId} layout={VibeShare} path="/timetracking" component={ TimeTracker } />
                }
              </UserContext.Provider>
            </Switch>
          </BrowserRouter>
          }

          {this.state.loaded === false &&
          <div className="loadingCircleWrapper d-flex flex-column">
              <img id="logo" src={logo_dark} height="50px" alt="vibeshare logo"/>
              <br/>
              <CircularProgress className="loadingCircle" />
          </div>
          }
        </div>
      </div>
    );
  }
}
export default App;

