import React, { } from 'react';
import { convertTimestamp, getExcerpt } from '../../utils/Helper';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import MoreMenu from '../dialog/MoreMenu';
import fire from 'firebase/app';
 
function UFPageItem(props) {
    let { path, url } = useRouteMatch();
    let history = useHistory();

    var str = props.page.data;
    var excerpt = str.match(/<p>(.*?)<\/p>/g);
    if(excerpt != null && excerpt[0] != null) {
        excerpt = excerpt[0];
        excerpt = excerpt.replace(/<\/?p>/g,'');
        excerpt = getExcerpt(excerpt,20)+"...";
    }

    const edit = () => {
        history.push(`${url}/edit/${props.page.postid}`);
    };

    const del = () => {
        fire.database().ref("/unfilteredpages/"+props.page.postid).remove();
    };

    const view = () => {
        history.push(`${url}/${props.page.postid}`);
    };

    return (
        <div className="uf-page-item-wrapper">
        <div className="uf-page-item">
            <div className="header p-2 bg-primary">
            <MoreMenu edit={edit} del={del} view={view}/>
            <Link to={`${url}/${props.page.postid}`}>
                <h5 className="mb-0">{props.page.title}</h5>
            </Link>
            </div>
            <div className="content p-2">
                <p className="" dangerouslySetInnerHTML={{__html:excerpt}} />
                <small>Last changes {convertTimestamp(props.page.timestamp)}</small>
            </div>
        </div>
        </div>
    )
}

export default UFPageItem;
