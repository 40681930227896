import React from 'react';
import { Button } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ConfirmationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    confirm = (e) => {
        e.preventDefault();
        document.body.classList.remove('noscroll');
        this.setState({password: ''});
        this.props.checkConfirmation(true,this.state.password);
    }

    decline = () => {
        document.body.classList.remove('noscroll');
        this.setState({password: ''});
        this.props.checkConfirmation(false,this.state.password);
    }

    componentDidMount() {
        document.addEventListener("keydown", event => {
            if (event.isComposing || event.keyCode === 27) {
                this.decline();
            }
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        if(this.props.showDialog === true) {
            document.body.classList.add('noscroll');
        }else {
            document.body.classList.remove('noscroll');
        }

        return(
            <TransitionGroup component={null}>
            {this.props.showDialog &&
            <CSSTransition classNames="fade" timeout={300}>
            <div className="modal confirmationDialog" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Are you sure you want to delete your account?</h5>

                </div>
                <form onSubmit={this.confirm}>
                <div className="modal-body">
                    <p>You will not be able to use your account anymore and <u><b>all</b></u> of your user data will be deleted immediately.</p>
                    <label>Input password to delete account:</label>
                    <input type="password" name="password" value={this.state.password} onChange={this.handleChange} required/>
                </div>
                <div className="modal-footer">
                    <Button type="submit">Yes, delete</Button>
                    <Button variant="outline" onClick={this.decline}><FontAwesomeIcon icon="window-close"/> Cancel</Button>
                </div>
                </form>
                </div>
            </div>
            </div>
            </CSSTransition>}
            </TransitionGroup>
        );
    }
}