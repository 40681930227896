import React,{ useContext } from 'react';
import VibeHeader from './VibeHeader';
import VibeFooter from './VibeFooter';
import { UserContext } from '../../utils/UserContext';

function DefaultLayout(props) {
    const user = useContext(UserContext)

    return (
        <div className="container-fluid fullSect">
        <VibeHeader user={user} hideBtnRight={props.hideBtnRight}></VibeHeader>
        {props.children}
        <div className="container-fluid fullSect">
            <VibeFooter />
        </div>
        </div>
    );
}

export default DefaultLayout;