import fire from '../../config/Fire';
import React from 'react';
import { writeUserData, loginGoogle } from '../../utils/Helper';

export default class SignupForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.signup = this.signup.bind(this);
        this.login = this.login.bind(this);
        this.state = {
          email: '',
          password: '',
          name: '',
          firstname: '',
          username: '',
          registering: null,
        };
      }
    
      handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }

      componentWillUnmount() {
        document.body.classList.remove('signup');
      }

      signup(e){
        this.setState({ registering: true });
        e.preventDefault();
        fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
            writeUserData(this.state.username,this.state.firstname, this.state.name,this.state.email,u.user.uid);
            u.user.sendEmailVerification()
        }).catch((error) => {
          if(error != null) {
            var emailHelp = document.getElementById("emailHelp");
            emailHelp.style.setProperty("color","red","important");
            emailHelp.innerHTML = error.message;
            console.log(error.message);
          } 
        });
      }
          
      login(e) {
        e.preventDefault();
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
          console.log("successfully logged in");
        }).catch((error) => {
            console.log(error);   
            document.getElementById("emailHelp").style.setProperty("color","red","important");
            document.getElementById("emailHelp").innerHTML = error.message;
        });
      }

    render() {
        return(
            <form className="loginForm">
            <div className="form-group">
                <label htmlFor="name">First name</label>
                <input required value={this.state.firstname} onChange={this.handleChange} type="text" name="firstname" className="form-control" id="firstname" placeholder="Enter your first name" />
            </div>
            <div className="form-group">
                <label htmlFor="name">Last name</label>
                <input required value={this.state.name} onChange={this.handleChange} type="text" name="name" className="form-control" id="name" placeholder="Enter your last name" />
            </div>
           <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input required value={this.state.email} onChange={this.handleChange} type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
           </div>
           <div className="form-group">
                <label htmlFor="username">Username</label>
                <input required value={this.state.username} onChange={this.handleChange} type="text" name="username" className="form-control" id="username" placeholder="Enter username" />
            </div>
            <div className="form-group">
           <label htmlFor="password">Password</label>
           <input required value={this.state.password} onChange={this.handleChange} type="password" name="password" className="form-control" id="password" placeholder="Enter Password" />
           </div>
           <div className="form-group">
           <button type="submit" onClick={this.signup} className="btn btn-primary">Create Account</button>
           </div>
            { false && <button onClick={loginGoogle} variant="link" className="mt-1 btn btn-link">Or sign in with Google <i className="fab fa-google"></i></button>}
            </form>
        ); 
    }
}