import React from 'react';
import {getPostUsername, convertTimestamp} from '../../utils/Helper';
import ProfilePicture from '../profile/ProfilePicture';

class VibePost extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            replyid: null,
            showForm: false,
            timestamp: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;
        getPostUsername(that.props.userid,function(data){
            if(that._isMounted) {
                that.setState({
                    timestamp: convertTimestamp(that.props.timestamp),
                    username: data.username,
                    firstname: data.firstname,
                    replyid: that.props.replyid,
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    toggleReplyForm = (e) => {
        const that = this;
        if(this.state.showForm) {
            that.setState({
                showForm: false,
            }); 
        }else {
            that.setState({
                showForm: true,
            }); 
        }
    }

    cancelReplyCallback = () => {
        this.setState({
            showForm: false,
        }); 
    }

    render() {
        return (
            this.state.replyid != null?<div className="vibeReply col-12">
                <div className="d-flex justify-content-start pb-2 align-items-center flex-row">
                    <ProfilePicture size="25" name={this.state.firstname} className="rounded-circle mr-2"/>
                    <div className="vibeUser"><b>{this.state.firstname}</b></div>      
                    <div className="vibeDate ml-auto">{this.state.timestamp}</div>          
                </div>
                <p className="vibeText">{this.props.replyContent}</p>
            </div>:<p></p>
        );    

    }
}

export default VibePost;
