import React from 'react';
import VibeFeed from '../post/VibeFeed';
import fire from '../../config/Fire';
import { Button } from 'react-bootstrap';
import { UserContext } from '../../utils/UserContext';
import { parseData } from '../../utils/Helper';

class VibeShareHome extends React.Component {
  static contextType = UserContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = ({
      user: null,
      loaded: false,
      vibesData: '',
      oldestKey: null,
    });
  }

  componentDidMount() {
    const user = this.context;
    console.log(this.props.authUsername)
    this._isMounted = true;
    const that = this;
    if(that._isMounted) {
      that.setState({
        user: user,
        userid: user.userId
      });
    }
    this.getPostData(function(data){
        if(that._isMounted) {
          that.setState({vibesData: parseData(data)});
        }
    });



  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPostData(cb) {
    fire.database().ref('/vibes').orderByKey().limitToLast(5).on('value', function(snapshot) {
      if(snapshot.val() != null) {
        cb(snapshot.val())
      } else {
        cb("");
      }
    });
  }

  refreshFeed() {
    const that = this;
    this.getPostData(function(data){
      if(that._isMounted) {
        that.setState({vibesData: data});
      }
    });
  }

  loadmore = () => {
    const that = this; 
    var arr = that.state.vibesData;
    var oldestKey = (arr[arr.length-1].postid);
    //load next 3 posts
    fire.database().ref('/vibes').orderByKey().endAt(""+oldestKey).limitToLast(4).once('value')
    .then((snapshot) => {
      if(that._isMounted) {
        var data = parseData(snapshot.val());
        data.splice(0,1);
        var prevData = that.state.vibesData;

        that.setState({vibesData: prevData.concat(data)});
      }
    })
    .catch((error) => {  

    });
  }

  render() {
      return (
        <div>
            <VibeFeed className="mx-auto" data={this.state.vibesData} authUsername={this.props.authUsername} authid={this.state.userid}></VibeFeed>
            <div className="row py-5 w-100">
              <Button className="mx-auto" onClick={this.loadmore}>Load more</Button>
            </div>
        </div>
      );
  }
}

export default VibeShareHome;