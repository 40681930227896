import React from 'react';
import fire from '../../config/Fire';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button } from 'react-bootstrap';
import { getCurrentTimestamp } from '../../utils/Helper';
import { PurpleSwitch } from '../form/PurpleSwitch';
import { UserContext } from '../../utils/UserContext';

class AppreciatonForm extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            public: false,
            appreciationContent: '',
            displaySubmit: false,
            userid: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleChecked = this.toggleChecked.bind(this);
    }

    handleChange(e) {
        if(this.state.appreciationContent.length >= 1) {
            this.setState({ displaySubmit: true });
            document.body.classList.add('appreciation');
        }
        
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        const user = this.context;
        this.setState({userid: user.userId});
    }

    postAppreciation = (e) => {
        e.preventDefault();
        const that = this;

        var postData = {
            appreciationContent: that.state.appreciationContent,
            userid: that.state.userid,
            timestamp: getCurrentTimestamp(),
            type: 'appreciation',
            public: that.state.public
        }
        console.log(postData);
        fire.database().ref('vibes/').push({
            content: that.state.appreciationContent,
            userid: that.state.userid,
            timestamp: getCurrentTimestamp(),
            type: 'appreciation',
            public: that.state.public
        }).then(function() {
            that.reinit();
            console.log("successfully wrote daily appreciation data to database");
        }).catch(function(error) {
          console.log(error.message);
        });

        document.body.classList.remove('appreciation');
    }

    toggleChecked = () => {
        const that = this;
        this.setState({ public: !that.state.public });
    }
 
    reinit() {
        this.setState({
            public: true,
            appreciationContent: '',
            displaySubmit: false
        });
    }

    render() {
        return(
            <div className="appreciationFormWrapper">
                <form className="appreciationForm" onSubmit={this.postAppreciation}>
                    <div className="w-100 row buttonWrap d-flex align-items-start justify-content-end flex-column">
                    <textarea onChange={this.handleChange} rows="3" required value={this.state.appreciationContent} name="appreciationContent" id="appreciationInput" placeholder="e.g.: Enter three things you are grateful for today... ;)"></textarea>
                    <TransitionGroup component={null}>
                    { this.state.displaySubmit &&
                    <CSSTransition classNames="fadeHeight" timeout={10}>
                        <>
                            <small className="font-italic mt-2">Try to always keep it short and simple</small>
                            <label className="mt-2">
                                Public
                                <PurpleSwitch size="small" checked={this.state.public} onChange={this.toggleChecked} />
                            </label>
                            <Button type="submit" className="mt-1" variant="primary">Submit</Button>
                        </>
                    </CSSTransition>
                    }
                    </TransitionGroup>
                    </div>
                </form>  
            </div>
        );
    }
}

export default AppreciatonForm;