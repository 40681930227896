import React, {Component} from 'react';
import Avatar from 'react-avatar';
import { UserContext } from '../../utils/UserContext';

export default class ProfilePicture extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            name: null
        }
    }

    componentDidMount() {
        const user = this.context;
        this.setState({name: user.name});
    }
    
    render() {
        return(
            <Avatar size={this.props.size} name={this.props.name} className={this.props.className}/>
        );
    }
}