import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from '../../utils/UserContext';

export default class SideNavigation extends React.Component {
    static contextType = UserContext;

    render() {
        const user = this.context;

        return(
            <div>
                <Nav className="flex-column sideNavigation">
                    <NavLink activeClassName="active" to="/home"><FontAwesomeIcon icon="home" /> Home</NavLink>
                    { false && <NavLink to="/notifications" activeClassName="active"><FontAwesomeIcon icon="bell" /> Notifications</NavLink>}
                    <NavLink to="/diary" activeClassName="active"><FontAwesomeIcon icon="book" /> Diary</NavLink>
                    <NavLink to="/unfilteredpages" activeClassName="active"><FontAwesomeIcon icon="file" /> Unfiltered Pages</NavLink>
                    {user.isAdmin && <NavLink to="/timetracking" activeClassName="active"><FontAwesomeIcon icon="clock" /> Time Tracking </NavLink>}
                </Nav>
            </div>
        );
    }
}